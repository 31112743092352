import { css } from '@emotion/react'
import { amountToString, multiplyByNumber, multiplyByRate, type Amount, type FinancialRate } from '@orus.eu/amount'
import type { RenewalDetails } from '@orus.eu/backend/src/services/contract-endorsement/contract-renewal-service'
import { addDurationToCalendarDate, calendarDateToString, formatDdMmYyyy, PARIS } from '@orus.eu/calendar-date'
import { nbsp } from '@orus.eu/char'
import {
  activitiesDistributionDimension,
  estimatedRevenueDimension,
  workforceDimension,
  type ActivitiesDistribution as ActivitiesDistributionDimensionType,
} from '@orus.eu/dimensions'
import {
  ActivitiesDistribution,
  Avatar,
  Button,
  Card,
  CheckboxContainer,
  colorTokens,
  Dialog,
  emoji,
  FormRow,
  PersistentNotification,
  spacing,
  Text,
  TextInputLabelWrapper,
  useDialogVisibility,
  useLanguage,
  useUiContext,
  ValidatedTextFieldFormField,
  type Activity,
} from '@orus.eu/pharaoh'
import type { ReactElement } from 'react'
import { useCallback, useMemo, useState } from 'react'
import { amountMapper } from '../../../lib/amount'
import { GlobalLoadingState } from '../../molecules/global-loading-state'
import {
  computeIsValid,
  mapActivitiesToDistribution,
} from '../subscription-v2/elements/activities-distribution-element/activities-distribution-element.utils'
import { mappers } from '../subscription-v2/elements/integer-element-mappers.utils'
import {
  RenewalButtonContainer,
  RenewalContainer,
  RenewalLeftBlockLayout,
  RenewalPageLayout,
  TextWithBottomSpacing,
} from './RenewalLayout'

export type RcdaFields = {
  activitiesDistribution: ActivitiesDistributionDimensionType
  workforce: number
  estimatedRevenue: Amount
  rcdaHasRevenueLimitExceeded: boolean
}

export const RenewalStartState = function RenewalStartState({
  goToSuccessState,
  goToFailState,
  renewalDetails,
  validateRenewal,
  isRenewalValidationPending,
  renewalValidationType,
}: {
  validateRenewal: (rcdaFields: RcdaFields) => void
  isRenewalValidationPending: boolean
  renewalValidationType: 'not-validated' | 'success-renewal' | 'excluded-renewal'
  goToSuccessState: () => void
  goToFailState: () => void
  renewalDetails: RenewalDetails
}): ReactElement | undefined {
  const uiContext = useUiContext()
  const language = useLanguage()

  const [estimatedRevenue, setEstimatedRevenue] = useState<Amount>(renewalDetails.estimatedRevenue)
  const [workforce, setWorkforce] = useState<number>(renewalDetails.workforce)
  const [maxPercentageForMaterialsTrading] = useState<FinancialRate>(renewalDetails.maxPercentageForMaterialsTrading)
  const [rcdaHasRevenueLimitExceeded, setRcdaHasRevenueLimitExceeded] = useState<boolean>(
    renewalDetails.rcdaHasRevenueLimitExceeded,
  )
  const [activitiesDistribution, setActivitiesDistribution] = useState<ActivitiesDistributionDimensionType | undefined>(
    renewalDetails.activitiesDistribution,
  )

  const estimatedRevenueInfoTooltip = `
    Montant HT sur le chiffre d'affaires prévisionnel (du
    ${calendarDateToString(renewalDetails.effectDate, PARIS, 'DATE_FULL', 'fr')} au
    ${calendarDateToString(addDurationToCalendarDate(renewalDetails.effectDate, { year: 1 }, PARIS), PARIS, 'DATE_FULL', 'fr')},
    cela nous permet de vous proposer le tarif le plus adapté.
  `

  //#region Activities distribution
  const {
    visible: isActivitiesDistributionDialogVisible,
    show: showActivitiesDistributionDialog,
    hide: hideActivitiesDistributionDialog,
  } = useDialogVisibility('activities-distribution')

  const mappedActivities: Activity[] = useMemo(
    () => mapActivitiesToDistribution(renewalDetails.activities, activitiesDistribution),
    [renewalDetails.activities, activitiesDistribution],
  )

  const [draftActivities, setDraftActivities] = useState(mappedActivities)

  const handleActivitiesDistributionValidate = useCallback(() => {
    hideActivitiesDistributionDialog()
    setActivitiesDistribution({
      type: 'multiple',
      distribution: Object.fromEntries(
        draftActivities.map((activity) => [activity.id, { sharePercentage: activity.sharePercentage }]),
      ),
    })
  }, [draftActivities, hideActivitiesDistributionDialog])

  const isActivitiesDistributionValid = useMemo(() => {
    return computeIsValid(draftActivities)
  }, [draftActivities])
  //#endregion

  const approveRenewal = () => {
    if (activitiesDistribution)
      validateRenewal({
        activitiesDistribution,
        workforce,
        estimatedRevenue,
        rcdaHasRevenueLimitExceeded,
      })
  }

  if (isRenewalValidationPending) return <GlobalLoadingState />

  if (renewalValidationType === 'success-renewal') {
    goToSuccessState()
  } else if (renewalValidationType === 'excluded-renewal') {
    goToFailState()
  }

  return (
    <RenewalPageLayout>
      <RenewalContainer gridTemplateColumns="1fr minmax(0, 400px)">
        <RenewalLeftBlockLayout>
          <Text variant="h4" color={colorTokens['color-text-base-main']}>
            Renouvellement de votre contrat RC{nbsp}Décennale
          </Text>
          <div>
            <TextWithBottomSpacing variant="body2" color={colorTokens['color-text-base-basic']}>
              Votre contrat arrive à échéance le {formatDdMmYyyy(renewalDetails.lastPeriodDay)}.
            </TextWithBottomSpacing>
            <Text variant="body2" color={colorTokens['color-text-base-basic']}>
              Merci de renseigner les informations suivantes dans le cadre du renouvellement de votre contrat d&apos;une
              année supplémentaire et confirmer votre tarif.
            </Text>
          </div>
          <FormRow alignItems="flex-end">
            <ValidatedTextFieldFormField
              label={estimatedRevenueDimension.displayNames?.[language]}
              size="large"
              value={estimatedRevenue}
              onChange={(value) => value && setEstimatedRevenue(value)}
              mapper={amountMapper}
              placeholder={estimatedRevenueDimension.placeholders?.[language]}
              infoTooltip={estimatedRevenueInfoTooltip}
            />
            <ValidatedTextFieldFormField
              label={workforceDimension.displayNames?.[language]}
              size="large"
              mapper={mappers['positive-integer']}
              value={workforce}
              onChange={(value) => value && setWorkforce(value)}
              placeholder={workforceDimension.placeholders?.[language]}
              infoTooltip={workforceDimension.hints?.[language]}
            />
          </FormRow>

          <CheckboxContainer
            size="medium"
            checked={rcdaHasRevenueLimitExceeded}
            onChange={setRcdaHasRevenueLimitExceeded}
          >
            Mon entreprise réalise plus de{' '}
            {`${amountToString(multiplyByRate(estimatedRevenue, maxPercentageForMaterialsTrading), { addCurrency: true, displayDecimals: false })} de négoce de matériaux`}
          </CheckboxContainer>
          <PersistentNotification
            variant="info"
            title={`Répartition de votre chiffre d'affaires (${amountToString(estimatedRevenue, {
              addCurrency: true,
              displayDecimals: false,
            })})`}
          >
            <ul
              css={css`
                margin-bottom: ${spacing[40]};
                padding-left: ${spacing[50]};
              `}
            >
              {mappedActivities.map((activity) => (
                <li key={activity.id}>
                  <Text variant="body2">{`${amountToString(
                    multiplyByNumber(estimatedRevenue, activity.sharePercentage / 100),
                    {
                      addCurrency: true,
                      displayDecimals: false,
                    },
                  )} en ${activity.name}`}</Text>
                </li>
              ))}
            </ul>
            <Button variant="text" size="small" style="info" onClick={showActivitiesDistributionDialog}>
              Modifier la répartition
            </Button>
          </PersistentNotification>
        </RenewalLeftBlockLayout>

        <div>
          <Card
            title="Attention aux pénalités en cas de retard"
            subtitle={
              <>
                Pour renouveler votre contrat en toute sérénité, pensez à mettre à jour vos informations le plus tôt
                possible, et avant le{' '}
                <Text variant="body2Medium" element="span">
                  {formatDdMmYyyy(renewalDetails.lastClientPossibleUpdateDate)}
                </Text>
              </>
            }
            avatar={<Avatar src={emoji.indexPointingUp} size="50" />}
            size="small"
            variant="basic"
            fullWidth
          >
            <PersistentNotification variant="warning">
              <Text variant="body2">Sans cela, 50 % d&apos;augmentation du tarif en cas de retard.</Text>
            </PersistentNotification>
          </Card>
        </div>

        <RenewalButtonContainer alignItems="flex-end">
          <Button
            onClick={approveRenewal}
            disabled={!activitiesDistribution || workforce === undefined || !estimatedRevenue}
            screenVariant={uiContext}
            size={uiContext === 'mobile' ? 'large' : 'medium'}
          >
            Continuer
          </Button>
        </RenewalButtonContainer>
      </RenewalContainer>
      {isActivitiesDistributionDialogVisible && (
        <Dialog
          size="large"
          title="Quelle est la répartition de votre chiffre d'affaires ?"
          onClose={hideActivitiesDistributionDialog}
          primaryActionLabel={'Valider'}
          onPrimaryAction={handleActivitiesDistributionValidate}
          primaryActionDisabled={!isActivitiesDistributionValid}
          secondaryActionLabel={'Annuler'}
          onSecondaryAction={hideActivitiesDistributionDialog}
        >
          <div
            css={css`
              width: 100%;
            `}
          >
            <TextInputLabelWrapper
              label={activitiesDistributionDimension.displayNames?.[language]}
              infoTooltip={activitiesDistributionDimension.hints?.[language]}
            >
              <ActivitiesDistribution
                screenType={uiContext === 'mobile' ? 'mobile' : 'desktop'}
                revenue={estimatedRevenue}
                activities={draftActivities}
                onUpdate={setDraftActivities}
                onCommit={setDraftActivities}
              />
            </TextInputLabelWrapper>
          </div>
        </Dialog>
      )}
    </RenewalPageLayout>
  )
}
